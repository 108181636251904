<template>
  <div class="error401">
    <div class="error401-body-con">
      <Card dis-hover>
        <div class="error401-body-con-title">
           <span class="error401-0-span">
            <Icon type="md-lock"></Icon>
          </span> <span class="error401-key-span">
            <Icon type="md-key"></Icon>
          </span>
        </div>
        <p class="error401-body-con-message">{{ messageText }}</p>
        <div class="error401-btn-con">
          <Button @click="backPage" size="large" style="width: 200px;margin-left: 40px;" type="primary">{{ pathName }}</Button>
        </div>
      </Card>
    </div>
  </div>
</template>
<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import {commonSessionStorage} from "@/utils/common";

export default {
  name: 'Error401',
  data() {
    return {
      messageText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000845'),
      pathName: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000846')
    };
  },
  created() {
    if (this.$route.query.type === '404') {
      this.messageText = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000847');
      this.pathName = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000848');
    } else {
      this.messageText = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000845');
      this.pathName = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000849');
    }
  },
  methods: {
    backPage() {
      let system = localStorage.getItem('system');
      // 判断当前系统是 yms 还是 supplier、distributor，然后跳转到指定系统有权限的页面
      if (system === 'yms' || system === 'supplier' || system === 'distributor') {
        this.getPagesPermissions();
      }
    },
    // 获取当前用户下都有哪些页面权限
    getPagesPermissions() {
      let v = this;
      let pagesMenuList = [];
      let pagesData = JSON.parse(localStorage.getItem('pagesPermissions'));
      if (pagesData) {
        function pagesMenu(menuData) {
          menuData.forEach((item) => {
            if (item.children && item.children.length > 0) {
              pagesMenu(item.children);
            } else {
              pagesMenuList.push(item);
            }
          });
        }

        pagesMenu(pagesData);
        if (pagesMenuList.length > 0) {
          let roleData = v.$store.state.roleData || commonSessionStorage.getItem('roleData');
          if (roleData) {
            for (let i = 0; i < pagesMenuList.length; i++) {
              let item = pagesMenuList[i];
              if (roleData.includes(item.menuKey)) {
                v.$router.push(item.path);
                return false;
              }
            }
          }
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
@keyframes error401animation {
  0% {
    transform: rotateZ(0deg);
  }
  40% {
    transform: rotateZ(-20deg);
  }
  45% {
    transform: rotateZ(-15deg);
  }
  50% {
    transform: rotateZ(-20deg);
  }
  55% {
    transform: rotateZ(-15deg);
  }
  60% {
    transform: rotateZ(-20deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

.error401 {
  position: relative;

  .error401-body-con {
    width: 700px;
    height: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0%);
  }

  .error401-body-con-title {
    text-align: center;
    font-size: 240px;
    font-weight: 700;
    color: #2d8cf0;
    height: 260px;
    line-height: 260px;
    margin-top: 40px;
  }

  .error401-0-span {
    display: inline-block;
    position: relative;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    border: 20px solid #ed3f14;
    color: #ed3f14;
    margin-right: 10px;
  }

  .error401-body-con-title .error401-0-span i {
    display: inline-block;
    font-size: 120px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .error401-key-span {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 170px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .error401-body-con-title .error401-key-span i {
    display: inline-block;
    font-size: 190px;
    position: absolute;
    left: 20px;
    transform: translate(-50%, -50%);
    transform-origin: center bottom;
    animation: error401animation 2.8s ease 0s infinite;

  }

  .error401-body-con-message {
    display: block;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 4px;
    color: #dddde2;
  }

  .error401-btn-con {
    text-align: center;
    padding: 20px 0;
    margin-bottom: 40px;
  }

}
</style>
